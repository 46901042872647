import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";


type ProConListProps = {
  title: "Pros" | "Cons",
  items: readonly (string | null)[]
}

export const ProConList:FC<ProConListProps> = ({ title, items }) => {

  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h5" component="h3">Top 5 {title}</Typography>
      <ol>
        {items.map( item => {
          return <li>{item}</li>
        })}
      </ol>
    </Grid>
  );
};

export default ProConList;
