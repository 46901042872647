import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";

import ProductReviewSummary from "../../components/reviewskew/product/ProductReviewSummary";


const ProductReviewSummaryByProductNamePage:FC<PageProps<Queries.ProductReviewSummaryQuery>> = ({ data }) => {
  return (
    <ProductReviewSummary data={data} />
  )
};

export default ProductReviewSummaryByProductNamePage;

export const pageQuery = graphql`
query ProductReviewSummary($slug: String!) {
    contentfulProductReviewSummary(slug: { eq: $slug }) {
      productName
      opinionJson {
        result_text
      }
      consJson {
        result_list
      }
      prosJson {
        result_list
      }
      asin
      updatedAt
    }
  }
`;
