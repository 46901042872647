import React, { FC } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

import StandardPageContainer from "../standardpage/StandardPageContainer";
import ProConList from "./ProConList";
import { Helmet } from "react-helmet";
import theme from "../../../gatsby-theme-material-ui-top-layout/theme";
import { Link } from "gatsby-theme-material-ui";


type ProductReviewSummaryProps = {
  data: Queries.ProductReviewSummaryQuery;
}

const amazonTag = theme.affiliates?.amazon?.tag
const maxOpinion = 100;

export const ProductReviewSummary:FC<ProductReviewSummaryProps> = ({ data }) => {

  const product = data.contentfulProductReviewSummary!
  const name = product.productName
  const opinion = product.opinionJson?.result_text
  const clippedOpinion = opinion?.length ?? 0 <= maxOpinion ? opinion : opinion?.slice(0, maxOpinion - 3) + "..."

  const pros = product.prosJson?.result_list
  const cons = product.consJson?.result_list
  const asin = product.asin // Optional

  return (
    <StandardPageContainer title={"Summary of reviews for " + name ?? "Unknown Product"}>
      <Helmet>
        <meta name="description" content={clippedOpinion ?? "AI generated review summary for " + name ?? "an unknown product"} />
        <meta name="revised" content={product.updatedAt ? product.updatedAt : "unknown"} />
      </Helmet>

      <Typography variant="h3" component="h1">{name ?? "Unknown Product"}</Typography>

      <Divider sx={{
        margin: 2
      }}>
        <Typography variant="h4" component="h2">
          Summary of Reviews
        </Typography>
        <Typography variant="caption" color={theme.palette.text.secondary}>
          (AI generated from reviews across the web)
        </Typography>
      </Divider>

      <Box>
        {opinion ?? "Oops! We couldn't locate our summary of reviews for " + (name ? "\"" + name + "\"" : "this product") + " at this time."}
      </Box>

      <Grid container spacing={4} justifyContent="space-around" marginTop={1}>
        {pros ? <ProConList title="Pros" items={pros} /> : null}

        {cons ? <ProConList title="Cons" items={cons} /> : null}
      </Grid>

      <Divider />

      <Box textAlign="center" margin={2}>
        <Typography variant="h4" component="div">
          {asin ?
            <a href={"https://www.amazon.com/dp/" + asin + "/?tag=" + amazonTag} target="_blank">Buy it on Amazon.com</a>
            :
            name ?
              <a href={"https://www.amazon.com/s?k=" + name + "&tag=" + amazonTag} target="_blank">Find it on Amazon.com</a>
              :
              null
          }
        </Typography>
      </Box>

      <Box textAlign="center">
        <Link href="/recent-reviews/">More Recent Review Summaries</Link>
      </Box>

    </StandardPageContainer>
  );
};

export default ProductReviewSummary;
